// Footer.js
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contacto" className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Contacto</h3>
              <p className="location">
                <i className="icofont-google-map"></i> Plaza de la Paz 102 Int.
                11, Puerto Interior. 36275, Silao, México
              </p>
              <span>
                <i className="icofont-email"></i>{" "}
                <a
                  className="contact-authority"
                  href="mailto:soporte.forogo@guanajuato.gob.mx"
                >
                  soporte.forogo@guanajuato.gob.mx
                </a>
              </span>
              <span>
                <i className="icofont-phone"></i>{" "}
                <a className="contact-authority" href="tel:+524773999182">
                  477 399 91 82
                </a>
              </span>
              <br />
              <br />
              <div className="logo">
                <Link to="/">
                  <img
                    width={300}
                    src={require("../../assets/foro-go/gto-cofoce_logos.png")}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <ul className="social-links">
                <li>
                  <Link
                    onClick={() => {
                      window.open("https://www.facebook.com/ForoGOgto/");
                    }}
                    to=""
                    className="facebook"
                    target="_blank"
                  >
                    <i className="icofont-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.youtube.com/channel/UCmOA92mD6BViJfFqLEoXC8g"
                    className="youtube"
                    target="_blank"
                  >
                    <i className="icofont-youtube-play"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://mx.linkedin.com/in/foro-go"
                    className="linkedin"
                    target="_blank"
                  >
                    <i className="icofont-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/forogogto/"
                    className="instagram"
                    target="_blank"
                  >
                    <i className="icofont-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.tiktok.com/discover/forogo?lang=es"
                    className="tiktok"
                    target="_blank"
                  >
                    <i className="fa-brands fa-tiktok"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="copyright-area">
              <div className="logo">
                <Link to="/">
                  <img
                    width={200}
                    src={require("../../assets/foro-go/forogo_blanco.png")}
                    alt="logo"
                  />
                </Link>
              </div>
              <ul>
                <li>
                  <Link to="aliados-estrategicos">Aliados Estratégicos</Link>
                </li>
                <li>
                  <Link to="/hoteles">Hoteles</Link>
                </li>
                <li>
                  <Link to="/pricing">Boletos</Link>
                </li>
                <li>
                  <Link to="/aviso">Aviso de Privacidad</Link>
                </li>
                <li>
                  <Link to="/terminos">Términos y Condiciones</Link>
                </li>
                <li>
                  <Link to="" target="_blank">
                    Blog
                  </Link>
                </li>
              </ul>
              <p style={{ color: "#01fff4" }}>
                <i
                  style={{ color: "#01fff4" }}
                  className="icofont-copyright"
                ></i>{" "}
                Todos los derechos reservados
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
