import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import Place from "./../../assets/foro-go/backgrond/lugar-fecha.png";
import Logo from "./../../assets/foro-go/backgrond/logo-forogo-slogan.webp";
import backgroundImage from "./../../assets/foro-go/backgrond/bg_inicio.webp";
import "./styles.css";

export default function MainBanner() {
  const [isMounted, setIsMounted] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isOpen, setIsOpen] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);
  /* eslint-disable */

  const openModal = () => {
    setIsOpen(true);
  };

  const makeTimer = () => {
    let endTime = new Date("October 16, 2024 00:00:00 PDT");
    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let auxDays = Math.floor(timeLeft / 86400);
    let auxHours = Math.floor((timeLeft - auxDays * 86400) / 3600);
    let auxMinutes = Math.floor(
      (timeLeft - auxDays * 86400 - auxHours * 3600) / 60
    );
    let auxSeconds = Math.floor(
      timeLeft - auxDays * 86400 - auxHours * 3600 - auxMinutes * 60
    );
    if (auxHours < "10") {
      auxHours = "0" + auxHours;
    }
    if (auxMinutes < "10") {
      auxMinutes = "0" + auxMinutes;
    }
    if (auxSeconds < "10") {
      auxSeconds = "0" + auxSeconds;
    }
    setDays(auxDays);
    setHours(auxHours);
    setMinutes(auxMinutes);
    setSeconds(auxSeconds);
  };

  const redirectTo = () => {
    window.open("https://boletos.foro-go.com/", "_blank");
  };

  useEffect(() => {
    if (isMounted) {
      setInterval(() => {
        makeTimer();
      }, 1000);
    }

    return () => {
      setIsMounted(false);
    };
  });

  const ResponsiveDiv = styled.div`
    position: relative;
    right: 250px;

    @media (max-width: 768px) {
      right: 150px;
    }

    @media (max-width: 480px) {
      right: 0;
      margin: 0 auto;
      width: 90%; /* or any suitable width */
    }
  `;

  const StyledImg = styled.img`
    width: 90%;
    height: auto;

    @media (max-width: 480px) {
      right: 30px;
    }
  `;

  const ButtonBox = styled.div`
    display: flex;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    .btn {
      margin-bottom: 1rem;
    }
    .video-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  return (
    <section id="home">
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '40%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999
        }}
      >
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="azjoiGW7utQ"
          onClose={() => setIsOpen(false)}
        />
      </div>
      <div
        style={{
          width: "auto",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          background: "#FEF9E7",
          backgroundSize: "cover",
          justifyContent: "center",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="">
          <ResponsiveDiv className="d-flex flex-column">
            <StyledImg alt="Logo" className="img-logo-p" src={Logo} />
            <br />
            <br />
            <StyledImg alt="Place" src={Place} />
            <br />
            <br />
            <ButtonBox>
              <button onClick={redirectTo} className="btn btn-primary">
                <strong>Adquiere Tus Pases</strong>
              </button>

              <Link
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
                to="#"
                className="video-btn popup-youtube"
              >
                <i className="icofont-ui-play"></i>
                <strong>Ver Video</strong>
              </Link>
            </ButtonBox>
          </ResponsiveDiv>

          <div className="event-countdown">
            <div id="timer">
              <div id="hours">
                {days} <span>Días</span>
              </div>
              <div id="hours">
                {hours} <span>Horas</span>
              </div>
              <div id="minutes">
                {minutes} <span>Min</span>
              </div>
              <div id="seconds">
                {seconds} <span>Seg</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
